/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const Create_User = /* GraphQL */ `
  mutation Create_User($input: CreateUserInput) {
    Create_User(input: $input)
  }
`;
export const Activate_Or_Deactivate_User = /* GraphQL */ `
  mutation Activate_Or_Deactivate_User($input: ActivateOrDeactiveUserInput) {
    Activate_Or_Deactivate_User(input: $input)
  }
`;
export const CreateMasterCategory = /* GraphQL */ `
  mutation CreateMasterCategory($input: CreateMasterCategoryInput) {
    CreateMasterCategory(input: $input)
  }
`;
export const CreateCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput) {
    CreateCategory(input: $input)
  }
`;
export const UpdateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput) {
    UpdateCategory(input: $input)
  }
`;
export const ActivateOrDeactiveCategory = /* GraphQL */ `
  mutation ActivateOrDeactiveCategory($input: ActivateOrDeactiveCategoryInput) {
    ActivateOrDeactiveCategory(input: $input)
  }
`;
export const Create_Organization = /* GraphQL */ `
  mutation Create_Organization($input: CreateOrganizationInput) {
    Create_Organization(input: $input)
  }
`;
export const Update_Organization = /* GraphQL */ `
  mutation Update_Organization($input: UpdateOrganizationInput) {
    Update_Organization(input: $input)
  }
`;
export const Activate_Or_Deactivate_Organization = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Organization(
    $input: ActivateOrDeactiveOrganizationInput
  ) {
    Activate_Or_Deactivate_Organization(input: $input)
  }
`;
export const AddManufacturer = /* GraphQL */ `
  mutation AddManufacturer($input: AddManufacturerInput) {
    AddManufacturer(input: $input)
  }
`;
export const CreateServiceType = /* GraphQL */ `
  mutation CreateServiceType($input: CreateServiceTypeInput) {
    CreateServiceType(input: $input)
  }
`;
export const UpdateServiceType = /* GraphQL */ `
  mutation UpdateServiceType($input: UpdateServiceType) {
    UpdateServiceType(input: $input)
  }
`;
export const CreateServicesUnderCategories = /* GraphQL */ `
  mutation CreateServicesUnderCategories(
    $input: CreateServicesUnderCategoriesInput
  ) {
    CreateServicesUnderCategories(input: $input)
  }
`;
export const UpdateMasterServices = /* GraphQL */ `
  mutation UpdateMasterServices($input: UpdateMasterServicesInput) {
    UpdateMasterServices(input: $input)
  }
`;
export const ActivateOrDeactiveServices = /* GraphQL */ `
  mutation ActivateOrDeactiveServices($input: ActivateOrDeactiveServicesInput) {
    ActivateOrDeactiveServices(input: $input)
  }
`;
export const UploadBannersToS3 = /* GraphQL */ `
  mutation UploadBannersToS3($input: UploadBannersToS3Input) {
    UploadBannersToS3(input: $input)
  }
`;
export const ReloadCreditsOfEnterprise = /* GraphQL */ `
  mutation ReloadCreditsOfEnterprise($input: ReloadEnterpriseCreditsInput) {
    ReloadCreditsOfEnterprise(input: $input)
  }
`;
export const ReloadPartnerRepresentativeCredits = /* GraphQL */ `
  mutation ReloadPartnerRepresentativeCredits(
    $input: ReloadPartnerRepresentativeCreditsInput
  ) {
    ReloadPartnerRepresentativeCredits(input: $input)
  }
`;
export const UploadTutorialFiles = /* GraphQL */ `
  mutation UploadTutorialFiles($input: UploadTutorialFilesInput) {
    UploadTutorialFiles(input: $input)
  }
`;
export const DeleteTutorialFile = /* GraphQL */ `
  mutation DeleteTutorialFile($input: DeleteTutorialFileInput) {
    DeleteTutorialFile(input: $input)
  }
`;
export const UpdateExpiryApiKey = /* GraphQL */ `
  mutation UpdateExpiryApiKey {
    UpdateExpiryApiKey
  }
`;
export const CreateModule = /* GraphQL */ `
  mutation CreateModule($input: CreateModuleInput) {
    CreateModule(input: $input)
  }
`;
export const UpdateModule = /* GraphQL */ `
  mutation UpdateModule($input: UpdateModuleInput) {
    UpdateModule(input: $input)
  }
`;
export const DeleteModule = /* GraphQL */ `
  mutation DeleteModule($input: DeleteModuleInput) {
    DeleteModule(input: $input)
  }
`;
export const CreateTicketType = /* GraphQL */ `
  mutation CreateTicketType($input: CreateTicketTypeInput) {
    CreateTicketType(input: $input)
  }
`;
export const sendNotificationToUser = /* GraphQL */ `
  mutation SendNotificationToUser($input: sendNotificationToUserInput) {
    sendNotificationToUser(input: $input)
  }
`;
export const CreateNotificationSettings = /* GraphQL */ `
  mutation CreateNotificationSettings($input: CreateNotificationSettingsInput) {
    CreateNotificationSettings(input: $input)
  }
`;
export const UpdateNotificationSettings = /* GraphQL */ `
  mutation UpdateNotificationSettings($input: UpdateNotificationSettingsInput) {
    UpdateNotificationSettings(input: $input)
  }
`;
export const GenerateS3Url = /* GraphQL */ `
  mutation GenerateS3Url($input: GenerateS3UrlInput) {
    GenerateS3Url(input: $input)
  }
`;
export const UpdateGlobalRaydeoApiKeys = /* GraphQL */ `
  mutation UpdateGlobalRaydeoApiKeys($input: UpdateGlobalRaydeoApiKeysInput) {
    UpdateGlobalRaydeoApiKeys(input: $input)
  }
`;
export const UpdateOtpForUsers = /* GraphQL */ `
  mutation UpdateOtpForUsers($input: UpdateOtpForUsersInput) {
    UpdateOtpForUsers(input: $input)
  }
`;
