<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="400px" v-model="addAdmindialog">
      <v-toolbar class="primary" dense dark>
        <v-toolbar-title class="ml-2 white--text">Add Admin</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="addAdmindialogEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text class="mt-4 pb-0">
          <v-form ref="form">
            <v-text-field
              outlined
              dense
              label="Email ID *"
              class="FontSize field_label_size field_height"
              v-model="adminemail"
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{3,3})+$/.test(v) ||
                  'Email must be valid',
              ]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            @click="validateaddAdmin"
            class="mr-2 secondary white--text"
            >ADD</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { Create_User } from "@/graphql/mutations.js";
export default {
  props: {
    addAdmindialog: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    adminemail: "",
    isLoading: false,
    SnackBarComponent: {},
  }),
  methods: {
    validateaddAdmin() {
      if (this.$refs.form.validate()) {
        this.addAdmin();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all the required details",
        };
      }
    },
    async addAdmin() {
      this.isLoading = true;
      try {
        var inputParams = {
          user_email_id: this.adminemail,
          creater_email_id: this.$store.getters.get_user_email,
        };
        let result = await API.graphql(
          graphqlOperation(Create_User, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.Create_User);
        if (ResultObject.status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.addAdmindialogEmit((this.Toggle = 2));
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    addAdmindialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
