import { List_Users } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllManageAdminsList = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllAdminsList: [],
      GetAllAdminsListFilter: [],
    };
  },
  methods: {
    async GetAllManageAdminsMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(List_Users, {
            input: {
              organization_status: "ACTIVE",
            },
          })
        );
        this.GetAllAdminsList = JSON.parse(
          result.data.List_Users
        ).data.items.filter((item) => item.user_status == "ACTIVE");
        this.GetAllAdminsListFilter = JSON.parse(
          result.data.List_Users
        ).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllAdminsList = [];
        this.GetAllAdminsListFilter = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
