<template>
  <div>
    <Overlay :overlay="overlay" />
    <DeleteAdmin
      :dialogDeleteAdmin="dialogDeleteAdmin"
      :StoreObj="StoreObj"
      @clicked="dialogDeleteAdminEmit"
    />
    <AddAdmins :addAdmindialog="addAdmindialog" @clicked="addAdmindialogEmit" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">Manage Admins</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              class="FontSize mt-9 field_label_size field_height mr-2"
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-select
              outlined
              dense
              class="
                FontSize
                mt-9
                maxWidthSmall
                field_label_size field_height
                mr-2
              "
              label="Status"
              v-model="user_status"
              :items="StatusItems"
            ></v-select>
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  @click="addAdmindialog = true"
                  v-on="on"
                  v-bind="attrs"
                  class="primary white--text mt-2"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span class="white--text">Add Admin</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          class="elevation-0"
          dense
          :headers="headers"
          :items="GetAllAdminsList"
          :no-data-text="noDataText"
          :search="search"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
        >
          <template v-slot:[`item.user_email_id`]="{ item }">
            <div class="FontSize">{{ item.user_email_id }}</div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip
              bottom
              color="primary"
              v-if="
                item.user_id != $store.getters.get_current_user_details.user_id
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="checkItem(item)"
                  :color="item.user_status == 'ACTIVE' ? 'red' : 'green'"
                  small
                  v-on="on"
                  class="red--text"
                >
                  {{
                    item.user_status == "ACTIVE"
                      ? "mdi-close-thick"
                      : "mdi-check"
                  }}
                </v-icon>
              </template>
              <span class="white--text">
                {{
                  item.user_status == "ACTIVE"
                    ? "De-Activate this Admin ?"
                    : "Activate this Admin ?"
                }}</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import AddAdmins from "@/components/ManageAdmins/Dialogs/AddAdmins.vue";
import DeleteAdmin from "@/components/ManageAdmins/Dialogs/DeleteAdmin.vue";
import { GetAllManageAdminsList } from "@/mixins/GetAllManageAdminsList.js";
export default {
  components: {
    Overlay,
    AddAdmins,
    DeleteAdmin,
  },
  mixins: [GetAllManageAdminsList],
  data: () => ({
    TableHeight: 0,
    search: "",
    noDataText: "",
    user_status: "ACTIVE",
    overlay: false,
    addAdmindialog: false,
    dialogDeleteAdmin: false,
    GetAllAdminsList: [],
    GetAllAdminsListFilter: [],
    StatusItems: ["ACTIVE", "INACTIVE"],
    headers: [
      { text: "Email ID", value: "user_email_id" },
      { text: "Actions", value: "Actions" },
    ],
    StoreObj: {},
  }),
  watch: {
    user_status(val) {
      this.GetAllAdminsList = this.GetAllAdminsListFilter.filter(
        (item) => item.user_status == val
      );
      if (this.GetAllAdminsList.length == 0) {
        this.noDataText = `No ${val} User Found `;
      }
    },
  },
  mounted() {
    this.GetAllManageAdminsMethod();
    this.TableHeight = window.innerHeight - 200;
  },
  methods: {
    addAdmindialogEmit(Toggle) {
      this.addAdmindialog = false;
      if (Toggle == 2) {
        this.user_status = "ACTIVE";
        this.GetAllManageAdminsMethod();
      }
    },
    dialogDeleteAdminEmit(Toggle) {
      this.dialogDeleteAdmin = false;
      if (Toggle == 2) {
        this.user_status = "ACTIVE";
        this.GetAllManageAdminsMethod();
      }
    },
    checkItem(item) {
      this.StoreObj = item;
      this.dialogDeleteAdmin = true;
    },
  },
};
</script>
